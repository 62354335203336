import React, {Component} from 'react';
import Nav from '../../components/Nav';
import Form from '../../components/form';
import {CheckCircle, XCircle} from 'react-feather';

// {
//     addOnData: "{"msisdn":"254726354124"}"
// createdAt: null
// email: "dkanake@TESTVIEWTEMPLATE.com"
// group: {id: 1, name: "Admins"}
// group_id: 1
// id: 4
// last_ip_login: null
// last_login_date: null
// name: "Dominic Kanake"
// password: "$2a$10$rRi.veiYZhhz7YyPN6ygxOEnAgQC4Q2Pi4q.aj9VYl4i9omXE/TH6"
// secondFactor_selected: "SMS"
// secondFactor_status: 0
// status: 1
// updatedAt: null
// verifyExpiry: null
// }

class AdminCreate extends Component {
    state = {};

    render() {
        return (
            <div className=''>
                <Nav name='Create System User'></Nav>
                <div className='d-flex flex-fill flex-column justify-content-center'>
                    <div className='mt-3 table-card border-0 card shado mx-3 shadow p-4'>
                        <Form
                            inputs={[
                                {
                                    label: 'name',
                                    name: 'name',
                                    type: 'text',
                                },
                                {
                                    label: 'Phone Number',
                                    name: 'msisdn',
                                    type: 'tel',
                                },
                                {
                                    label: 'email',
                                    name: 'email',
                                    type: 'email',
                                },
                                {
                                    label: 'password',
                                    name: 'password',
                                    type: 'password',
                                },
                                {
                                    label: 'secondFactor selected',
                                    name: 'secondFactor_selected',
                                    type: 'select',
                                    options: [
                                        {
                                            name: 'SMS',
                                            value: 'SMS',
                                        },
                                        {
                                            name: 'EMAIL',
                                            value: 'EMAIL',
                                        },
                                    ],
                                },

                                {
                                    label: 'secondFactor Status',
                                    name: 'secondFactor_status',
                                    type: 'select',
                                    options: [
                                        {
                                            name: 'Enabled',
                                            value: 0,
                                        },
                                        {
                                            name: 'Disabled',
                                            value: 1,
                                        },
                                    ],
                                },

                                {
                                    label: 'status',
                                    name: 'status',
                                    type: 'select',
                                    options: [
                                        {
                                            name: 'Active',
                                            value: 1,
                                        },
                                        {
                                            name: 'Inactive',
                                            value: 0,
                                        },
                                    ],
                                },

                                {
                                    label: 'group',
                                    name: 'group_id',
                                    type: 'select',
                                    options: window.groups.map((d) => {
                                        return {
                                            name: d.name,
                                            value: d.id,
                                        };
                                    }),
                                },
                            ]}
                            submitText={"Create"}
                            submit={(data) => {
                                this.createAdmin(data);
                            }}
                        ></Form>
                    </div>
                </div>
            </div>
        );
    }

    createAdmin = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Creating System User ...',
            buttons: false,
        });


        window.app
            .service('prerequisites/admins')
            .create(data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'System User created Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location = "/management/admins";
                    },

                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });
    };
}

export default AdminCreate;

import React from 'react';
import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import {Info} from 'react-feather';
import socketio from '@feathersjs/socketio-client';
import Button from "@material-ui/core/Button";
import RowPreview from "./components/RowPreview";
import Access from "./components/accessManager";
import {Link} from "react-router-dom";

const rest = require('@feathersjs/rest-client');
const request = require('request');

let endpoint = 'https://portalapi.microfinancebisoubisou.com'
// let endpoint = 'http://localhost:3030'

window.bi_endpoint = 'https://bi.microfinancebisoubisou.com'
let use_socketio = false;


window.app = feathers();

window.fxn = {
    clean_filtered_columns: (_obj, ref = {}) => {

        _obj = Object.entries(_obj).reduce((currentValue, [key, value], currentIndex) => {
            if (currentIndex === 0) {
                currentValue[key] = (
                    <>
                        <RowPreview value={key === "id" ? "#" : value} _obj={_obj}/>
                    </>
                )
            } else {
                let currency_found = false
                let status_found = false

                // if(key ==="currency_code"){return currentValue}

                if (key.includes("Obj")) {
                    if (["customerObj"].includes(key)) {
                        console.debug(">>", key, value)
                        currentValue[key.replace("Obj", "")] = (
                            <Link
                                className=''
                                to={`/management/focus/view/customer/${value.id}/details`}>
                                {value.first_name} {value.middle_name} {value.last_name}
                            </Link>
                        )
                    }

                } else {


                    switch (key) {
                        // case "customer_id":
                        //     currentValue[".Customer"] = (
                        //         <Link
                        //             className=''
                        //             to={`/management/focus/view/customer/${value.id}/details`}>
                        //             {value.first_name} {value.middle_name} {value.last_name}
                        //         </Link>
                        //     )
                        case "currency_id":
                            try {
                                currentValue['.Currency'] = window.currency?.filter(x => x.id?.toString() === value.toString())[0]['currency_name']
                                break;
                            } catch (err) {
                                currentValue['.Currency'] = "Default"
                                break;
                            }
                        case "status_id":
                            try {
                                currentValue['.Status'] = window.global_status?.filter(x => x.id?.toString() === value.toString())[0]['status']
                                break;
                            } catch (err) {
                                currentValue['.Status'] = "Pending/Disabled"
                                break;
                            }
                        case "branch_id":
                            try {
                                currentValue['.Branch'] = window.branch?.filter(x => x.id?.toString() === value.toString())[0]['name']
                                break;
                            } catch (err) {
                                currentValue['.Branch'] = "Default"
                                break;
                            }
                        case "language_id":
                            try {
                                currentValue['.Language'] = window.language?.filter(x => x.id?.toString() === value.toString())[0]['language_name']
                                break;
                            } catch (err) {
                                currentValue['.Language'] = "Default"
                                break;

                            }
                        default:
                            currentValue[key] = value

                    }


                }


            }

            return currentValue
        }, {})


        let ky = Object.keys(_obj)
        for (let i = 0; i < ky.length; i++) {
            if (ky[i] in ref && !["id"].includes(ky[i]))
                delete _obj[ky[i]]

        }
        // console.debug("LLL",_obj)
        return _obj
    }
}
if (use_socketio) {
    const socket = io(endpoint);
    window.app.configure(socketio(socket, {
        timeout: 10000,
    }));
} else {
    const restClient = rest(`${endpoint}`, {
        headers: {}
    })
    window.app.configure(restClient.request(request));
}


window.app.configure(
    feathers.authentication({
        storage: window.localStorage,
    })
);

//
// window.app.authentication.handleError({ code: 401 }, 'logout')
//   .then((err) => {})
//   .catch((err) => {
//     console.debug("Error");
//     console.debug(err);
//     // if (localStorage.token) {
//     let redirect=true
//     if(window.location.pathname =="/login") redirect=false
//
//
//     if(redirect)
//         Swal.fire({
//             title: 'Your session has Expired',
//             icon: 'info',
//             text:'Please login once more to validate your session',
//             showDenyButton: false,
//             showCancelButton: false,
//             confirmButtonText: `LogOut`,
//           }).then((result) => {
//             /* Read more about isConfirmed, isDenied below */
//             if (result.isConfirmed) {
//               window.app.logout();
//               if(redirect){
//                 window.location = '/login';
//                 localStorage.clear();
//                 Swal.fire('Bye Bye.', '', 'success')
//
//               }
//             } else if (result.isDenied) {
//               Swal.fire('Your Session is expired. Expect a few issues', '', 'info')
//             }
//           })
//
//         });

// window.app.service('authentication')
//   .on('error')
//   .then(() => {


//     Swal.fire({
//       title: 'Your session has Expired',
//       icon: <XCircle size={70} className='my-3'></XCircle>,
//       text:'Please login once more to validate your session',
//       showDenyButton: false,
//       showCancelButton: false,
//       confirmButtonText: `LogOut`,
//     }).then((result) => {
//       /* Read more about isConfirmed, isDenied below */
//       if (result.isConfirmed) {
//         window.app.logout();
//         window.location = '/login';
//         localStorage.clear();  
//         Swal.fire('Bye Bye.', '', 'success')
//       } else if (result.isDenied) {
//         Swal.fire('Your Session is expired. Expect a few issues', '', 'info')
//       }
//     })
// });

window.toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

if (localStorage.user) window.user = JSON.parse(localStorage.user);

window.logout = (force) => {
    if (force) {
        window.app.logout();
        window.location = '/login';
        localStorage.clear();
        return false;
    }

    window.alert2.confirm({
        icon: <Info size={70} className='my-3'></Info>,
        title: 'Are you sure that you want to Logout ?',
        message: 'If you agree your session will be terminated',
        confirmText: 'Log Out',
        onSubmit: () => {
            window.app.logout();
            window.location = '/login';
            window.fxn = null
            localStorage.clear();
        },
    });
};

window.get = (model, callback, filter) => {
    window.app
        .service(model)
        .find(filter ? filter : {})
        .then((response) => {
            callback(response);
        })
        .catch((err) => {
            console.error(err);
        });
};

window.formatNumber = (x) => {
    if (isNaN(x)) return x;

    if (x < 9999) {
        return x;
    }

    if (x < 1000000) {
        return Math.ceil(x / 1000) + 'K';
    }
    if (x < 10000000) {
        return (x / 1000000).toFixed(2) + 'M';
    }

    if (x < 1000000000) {
        return Math.ceil(x / 1000000) + 'M';
    }

    if (x < 1000000000000) {
        return Math.ceil(x / 1000000000) + 'B';
    }

    return '1T+';
};

window.generateID = (length) => {
    var result = '';
    var characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

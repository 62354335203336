import React, {Component} from 'react';
import {Link, Route} from 'react-router-dom';
import {CheckCircle, Info, User, XCircle} from 'react-feather';
import Tabs from '../../../components/tabs';

import {default as Summary} from './Tab_Summary';


// import FocuscustomerService from "./../customer/index";
// import FocuscustomerDetailService from "./../customer/DetailView";

import Focusloan_accountService from "./../loan_account/index";
import Focusloan_accountDetailService from "./../loan_account/DetailView";
import Focusloan_eligibilityService from "./../loan_eligibility/index";
import Focusloan_eligibilityDetailService from "./../loan_eligibility/DetailView";

import Focussavings_accountService from "./../savings_account/index";
import Focussavings_accountDetailService from "./../savings_account/DetailView";
import logo from "../../../img/logo.png";
import Access from "../../../components/accessManager";
import Modal from "../../../components/Modal";

class DetailView extends Component {
    state = {};

    render() {
        let user = [];

        return (
            <div className='bg-light'>
                <div className='card table-card border-0 shadow mx-3 mt-3 user-card'>
                    <div className='text-mute pt-3 pl-3'>
                        <small className='text-mute'>customer &gt; View</small>
                    </div>

                    <div className='profile p-3 d-md-flex flex-row align-items-center justify-content-between'>
                        <div className='d-md-flex flex-row align-items-center'>
                            <div
                                className='border avatar-lg bg-light d-flex flex-row align-items-center justify-content-center'>
                                <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                                    <img src={"/public/uploads/1712857443091-1000221893.jpg"} className='main-logo'
                                         alt=''/>
                                </div>

                            </div>
                            <div className='ml-md-4 my-3 my-md-0'>
                                <h4 className='text-capitalize'>
                                    {this.state.first_name + " " + this.state.middle_name}
                                </h4>
                                <div>CustomerID: <code>{this.state.customer_id}</code></div>
                                <div>Msisdn: <code>{this.state.msisdn1}</code>, <code>{this.state.msisdn2}</code></div>
                                <div className='ml-2 mt-1'>
                                    <span
                                        className={this.state.status_id != 1 ? "badge-danger px-2" : "badge-success px-2"}>{this.state.status_id == 1 ? "Active" : "Inactive/Suspended"}</span>
                                </div>
                            </div>
                        </div>

                        <div className='d-flex flex-row flex-wrap member-view '>
                            <div className='d-md-flex flex-row text-center flex-wrap admin-actions justify-content-end'>
                                <Access permission='all' _permission='ACTIONS_customer_EDIT_PROFILE'>

                                    &nbsp;&nbsp;
                                    <button onClick={() => {

                                        let cc = this.state

                                        // if (!cc.account_no) cc['account_no'] = "-"
                                        // if (!cc.serial_no) cc['serial_no'] = "-"
                                        this.setState({
                                            editModal: true,
                                            update_fields: {action: "EDIT_PROFILE", errors: {}, ...cc}
                                        });

                                    }} className={"btn btn-sm btn-round btn-outline-primary px-3"}>Edit
                                        Profile
                                    </button>
                                </Access>
                                &nbsp;&nbsp;
                                <Access permission='all' _permission='ACTIONS_customer_UPDATE_LIMIT'>
                                    <Link
                                        to={`/management/focus/view/customer/${this.props.match.params.id}/loan_eligibility`}
                                        className='btn btn-sm btn-round btn-dark px-3'
                                    >
                                        Update Loan Limit Score
                                    </Link>


                                </Access>
                                &nbsp;&nbsp;
                                <Access permission='all' _permission='ACTIONS_customer_BLOCK_USER'>
                                    {this.state.status_id !== 1 ? (<button onClick={() => {
                                        this.setState({
                                            editModal: true,
                                            update_fields: {
                                                action: "ACTIVATE_USER",
                                                errors: {},
                                                customer_id: this.state.customer_id,
                                                id: this.state.id,
                                                status_id: "1"
                                            }
                                        });
                                    }} className={"btn btn-sm btn-round btn-success px-3"}>Activate User
                                    </button>) : (<button onClick={() => {
                                        this.setState({
                                            editModal: true,
                                            update_fields: {
                                                action: "BLOCK_USER",
                                                errors: {},
                                                customer_id: this.state.customer_id,
                                                id: this.state.id,
                                                status_id: "5"
                                            }
                                        });
                                    }} className={"btn btn-sm btn-round btn-danger px-3"}>Block User
                                    </button>)}
                                </Access>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='position-relative px-3'>
                    {this.state.id && (
                        <Tabs>
                            <Route
                                permission='all'
                                path={`/management/focus/view/customer/${this.props.match.params.id}/details`}
                                title='Data'>
                                <Summary data={this.state}/>
                            </Route>

                            <Route
                                permission='all'
                                path={`/management/focus/view/customer/${this.props.match.params.id}/loans`}
                                title='Loans'>
                                <Focusloan_accountService data={this.state} filters={{customer_id: this.state.id}}/>
                            </Route>

                            <Route
                                permission='all'
                                path={`/management/focus/view/customer/${this.props.match.params.id}/loan_eligibility`}
                                title='Eligibility'>
                                <Focusloan_eligibilityService data={this.state} filters={{customer_id: this.state.id}}/>
                            </Route>

                            <Route
                                permission='all'
                                path={`/management/focus/view/customer/${this.props.match.params.id}/savings_account`}
                                title='Savings'>
                                <Focussavings_accountService data={this.state} filters={{customer_id: this.state.id}}/>
                            </Route>

                        </Tabs>
                    )}
                </div>


                <Modal
                    visible={this.state.editModal}
                    close={() => this.setState({editModal: false, update_fields: {errors: {}}})}>
                    <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                    <form onSubmit={this.editSendRequest}>
                        {this.state.update_fields?.id && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">CustomerID</span>
                            <input className="form-control bg-light text-input" name="id" id="id" type="text"
                                   value={this.state.update_fields?.id}
                                   onChange={this.handleChange} style={{width: '400px'}}/>
                        </div>)}

                        {this.state.update_fields?.customer_id && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Id</span>
                            <input className="form-control bg-light text-input" name="customer_id" id="customer_id"
                                   disabled={true} type="text"
                                   value={this.state.update_fields?.customer_id}
                                   onChange={this.handleChange} style={{width: '400px'}}/>
                        </div>)}


                        {this.state.update_fields?.first_name && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">FirstName</span>
                            <input className="form-control bg-light text-input" name="first_name" id="first_name"
                                   type="text"
                                   value={this.state.update_fields?.first_name} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.middle_name && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">MiddleName</span>
                            <input className="form-control bg-light text-input" name="middle_name" id="middle_name"
                                   type="text"
                                   value={this.state.update_fields?.middle_name} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.last_name && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">LastName</span>
                            <input className="form-control bg-light text-input" name="last_name" id="last_name"
                                   type="text"
                                   value={this.state.update_fields?.last_name} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}

                        {this.state.update_fields?.msisdn1 && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Msisdn1</span>
                            <input type="text" name="msisdn1" id="msisdn1"
                                   value={this.state.update_fields?.msisdn1} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.msisdn2 && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Msisdn2</span>
                            <input type="text" name="msisdn2" id="msisdn2" value={this.state.update_fields?.msisdn2}
                                   onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}


                        {this.state.update_fields?.email_address && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
                            <input type="text" name="email_address" id="email_address"
                                   value={this.state.update_fields?.email_address} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.work_place_address && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                            <input className="form-control bg-light text-input" name="work_place_address"
                                   id="work_place_address"
                                   type="text"
                                   value={this.state.update_fields?.work_place_address} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                            <div className="text-danger">{this.state.update_fields?.errors.work_place_address}</div>
                        </div>)}
                        {this.state.update_fields?.home_address && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                            <input className="form-control bg-light text-input" name="home_address" id="home_address"
                                   type="text"
                                   value={this.state.update_fields?.home_address} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                            <div className="text-danger">{this.state.update_fields?.errors.home_address}</div>
                        </div>)}
                        {/* {window.user.admin['group']['name'] === 'System Admins' && */}
                        {this.state.update_fields?.status_id && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">status_id:</span>
                            <select className="form-control bg-light text-input" name="status_id" id="status_id"
                                    type="select"
                                    value={this.state.update_fields?.status_id} onChange={this.handleChange}
                                    style={{width: '400px'}}>
                                <option value="1">ACTIVE</option>
                                <option value="5">SUSPEND</option>
                                <option value="6">DEACTIVATE</option>
                            </select>
                        </div>)}
                        {/* } */}

                        <div className="mx-3 d-inline-block mb-3 ">
                            <span className="ml-1 mb-2 d-block text-capitalize" style={{color: "red"}}>*Reason/Purpose of Change</span>
                            <input className="form-control bg-light text-input border-danger"
                                   name="reason_purpose"
                                   id="reason_purpose"
                                   type="text"
                                   aria-multiline={3}
                                   required={true}
                                   onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>

                        <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                            <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update
                            </button>
                        </div>
                    </form>
                </Modal>

            </div>
        );
    }

    componentDidMount = () => {
        this.fetchData();
    };


    get_image = (image_id) => {
        return window.app
            .service('/core/image')
            .get(image_id)
            .then((response) => {
                console.debug("Image Success:", response)
                return response
            })
            .catch((err) => {
                console.debug("Image Error:", err)
                return {}
            });
    };


    fetchData = () => {
        console.debug("ZZZZ>>", this.props.match.params)
        window.app
            .service('core/customer')
            .find({
                query: {
                    id: this.props.match.params.id
                }
            })
            .then((response) => {
                console.debug(response)
                response = response.data[0]
                console.debug("Record>>", response)

                this.setState(response);
            });

    };

    updateRecord = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Updating customer Data. Please Wait',
            buttons: false,
        });

        window.app
            .service('core/customer')
            .patch(this.state.id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    msisdn: this.state.msisdn,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        this.props.history.push('management/pipelines/' + response.id + '/details');
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                console.debug(err);
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}></Info>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };


    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState, update_fields: {
                ...prevState.update_fields,
                [id]: value
            }
        }))
    }

    validate(msisdn) {
        let errors = {};
        let isValid = true;
        if (!msisdn) {

            isValid = false;

            errors["msisdn"] = "Please enter your phone number.";

        }
        if (typeof msisdn !== "undefined") {

            var pattern = new RegExp(/^(\+){0,1}(254|0){0,1}(\d{9})$/);

            if (!pattern.test(msisdn)) {

                isValid = false;

                errors["msisdn"] = "Please enter valid phone number.";

            }

        }
        this.setState({

            errors: errors

        });


        return isValid;

    }

    editSendRequest
        = (event) => {
        event.preventDefault();


        let id = this.state.id;
        let data = {
            action: this.state.update_fields.action,
            ...this.state.update_fields.customer_id ? {customer_id: this.state.update_fields.customer_id} : {},
            ...this.state.update_fields.first_name ? {first_name: this.state.update_fields.first_name} : {},
            ...this.state.update_fields.last_name ? {last_name: this.state.update_fields.last_name} : {},
            ...this.state.update_fields.middle_name ? {middle_name: this.state.update_fields.middle_name} : {},
            ...this.state.update_fields.email_address ? {email_address: this.state.update_fields.email_address} : {},
            ...this.state.update_fields.msisdn1 ? {msisdn1: this.state.update_fields.msisdn1} : {},
            ...this.state.update_fields.msisdn2 ? {msisdn2: this.state.update_fields.msisdn2} : {},
            ...this.state.update_fields.work_place_address ? {work_place_address: this.state.update_fields.work_place_address} : {},
            ...this.state.update_fields.home_address ? {home_address: this.state.update_fields.home_address} : {}
        };
        if (!window.confirm("Are you sure that you want to update this record?")) return false;

        data.reason_purpose = this.state.update_fields.reason_purpose

        window.app
            .service('core/customer')
            .patch(id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'User Profile',
                    message: "Update Request Successful",
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });

            })
            .catch((err) => {
                console.debug(err)
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            });

    };


    get_action_results = ({action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(this.state.id, {query: {action_id, request_data_id: this.state.id}})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        // window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

    // update_data
    execute_action = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .create({
                action_id,
                action_name,
                action: `${action_id}`.toUpperCase(),
                request_data_id: this.state.id
            })
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: action_name,
                    message: JSON.stringify(response),
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: action_name + ' Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };


    update_data = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(37, {})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

}

export default DetailView;

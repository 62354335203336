import React, {Component} from 'react';
import Table from '../../../components/Table';
import Nav from '../../../components/Nav';
import Modal from "../../../components/Modal";
import {CheckCircle, Info, XCircle} from "react-feather";
import {Link} from "react-router-dom";
import Filter from "../../../components/filter";
import Access from "../../../components/accessManager";


class Officials extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        editModal: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='Officials'
                    buttons={[
                        // {
                        //     text: 'ADD',
                        //     link: '/management/customer',
                        // },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 '>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },

                                        ...(window.global_status || []).map((d) => {
                                            return {
                                                label: d.status_desc,
                                                value: d.id,
                                            };
                                        }),
                                    ],
                                },
                                // {
                                //     label: 'Currency',
                                //     name: 'currency_id',
                                //     options: [
                                //         {
                                //             label: 'All',
                                //             value: 0,
                                //             default: true,
                                //         },
                                //
                                //         ...window.currency
                                //             .map((d) => {
                                //                 return {
                                //                     label: d.currency_name,
                                //                     value: d.id,
                                //                 };
                                //             }),
                                //     ],
                                // }
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'createdAt'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchcustomer();
                                }, 0);
                            }}
                        />
                        <Table
                            search={[]}
                            // sort="created_at"
                            // sortDirection={-1}
                            refreshAllowed={true}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchcustomer();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                        <Modal
                            visible={this.state.editModal}
                            close={() => this.setState({editModal: false, update_fields: {errors: {}}})}>
                            <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                            <form onSubmit={this.editSendRequest}>
                                {this.state.update_fields?.id && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">CustomerID</span>
                                    <input className="form-control bg-light text-input" name="id" id="id" type="text"
                                           value={this.state.update_fields?.id}
                                           onChange={this.handleChange} style={{width: '400px'}}/>
                                </div>)}

                                {this.state.update_fields?.customer_id && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Id</span>
                                    <input className="form-control bg-light text-input" name="customer_id"
                                           id="customer_id" disabled={true} type="text"
                                           value={this.state.update_fields?.customer_id}
                                           onChange={this.handleChange} style={{width: '400px'}}/>
                                </div>)}


                                {this.state.update_fields?.first_name && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">FirstName</span>
                                    <input className="form-control bg-light text-input" name="first_name"
                                           id="first_name" type="text"
                                           value={this.state.update_fields?.first_name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}
                                {this.state.update_fields?.middle_name && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">MiddleName</span>
                                    <input className="form-control bg-light text-input" name="middle_name"
                                           id="middle_name"
                                           type="text"
                                           value={this.state.update_fields?.middle_name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}
                                {this.state.update_fields?.last_name && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">LastName</span>
                                    <input className="form-control bg-light text-input" name="last_name" id="last_name"
                                           type="text"
                                           value={this.state.update_fields?.last_name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}

                                {this.state.update_fields?.msisdn1 && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Msisdn1</span>
                                    <input type="text" name="msisdn1" id="msisdn1"
                                           value={this.state.update_fields?.msisdn1} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}
                                {this.state.update_fields?.msisdn2 && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Msisdn2</span>
                                    <input type="text" name="msisdn2" id="msisdn2"
                                           value={this.state.update_fields?.msisdn2}
                                           onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}


                                {this.state.update_fields?.email_address && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
                                    <input type="text" name="email_address" id="email_address"
                                           value={this.state.update_fields?.email_address} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>)}
                                {this.state.update_fields?.work_place_address && (
                                    <div className="mx-3 d-inline-block mb-3">
                                        <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                                        <input className="form-control bg-light text-input" name="work_place_address"
                                               id="work_place_address"
                                               type="text"
                                               value={this.state.update_fields?.work_place_address}
                                               onChange={this.handleChange}
                                               style={{width: '400px'}}/>
                                        <div
                                            className="text-danger">{this.state.update_fields?.errors.work_place_address}</div>
                                    </div>)}
                                {this.state.update_fields?.home_address && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                                    <input className="form-control bg-light text-input" name="home_address"
                                           id="home_address"
                                           type="text"
                                           value={this.state.update_fields?.home_address} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                    <div className="text-danger">{this.state.update_fields?.errors.home_address}</div>
                                </div>)}
                                {/* {window.user.admin['group']['name'] === 'System Admins' && */}
                                {this.state.update_fields?.status_id && (<div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">status_id:</span>
                                    <select className="form-control bg-light text-input" name="status_id" id="status_id"
                                            type="select"
                                            value={this.state.update_fields?.status_id} onChange={this.handleChange}
                                            style={{width: '400px'}}>

                                        {(window.global_status || []).map((d) => (
                                            <>
                                                <option value={d.id + ""}>{d.status_desc}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>)}
                                {/* } */}

                                <div className="mx-3 d-inline-block mb-3 ">
                                    <span className="ml-1 mb-2 d-block text-capitalize" style={{color: "red"}}>*Reason/Purpose of Change</span>
                                    <input className="form-control bg-light text-input border-danger"
                                           name="reason_purpose"
                                           id="reason_purpose"
                                           type="text"
                                           aria-multiline={3}
                                           required={true}
                                           onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>

                                <div
                                    className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button
                                        className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }


    editSendRequest
        = (event) => {
        event.preventDefault();


        let id = this.state.update_fields.id;
        let data = {
            action: this.state.update_fields.action,
            ...this.state.update_fields.customer_id ? {customer_id: this.state.update_fields.customer_id} : {},
            ...this.state.update_fields.first_name ? {first_name: this.state.update_fields.first_name} : {},
            ...this.state.update_fields.last_name ? {last_name: this.state.update_fields.last_name} : {},
            ...this.state.update_fields.middle_name ? {middle_name: this.state.update_fields.middle_name} : {},
            ...this.state.update_fields.email_address ? {email_address: this.state.update_fields.email_address} : {},
            ...this.state.update_fields.msisdn1 ? {msisdn1: this.state.update_fields.msisdn1} : {},
            ...this.state.update_fields.msisdn2 ? {msisdn2: this.state.update_fields.msisdn2} : {},
            ...this.state.update_fields.work_place_address ? {work_place_address: this.state.update_fields.work_place_address} : {},
            ...this.state.update_fields.home_address ? {home_address: this.state.update_fields.home_address} : {},
            ...this.state.update_fields.status_id ? {status_id: this.state.update_fields.status_id} : {}

        };
        if (!window.confirm("Are you sure that you want to update this record?")) return false;

        data.reason_purpose = this.state.update_fields.reason_purpose

        window.app
            .service('core/customer')
            .patch(id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'User Profile',
                    message: "Update Request Successful",
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });

            })
            .catch((err) => {
                console.debug(err)
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            });

    };


    fetchcustomer = () => {
        this.setState({table_loading: true});

        window.app
            .service('core/customer')
            .find({
                query: {
                    ...this.state.query,
                    role: 5
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    d['Actions'] = (
                        <Access permission='ACTIONS_OFFICIALS_EDIT_PROFILE'>
                            {d.status_id !== 1 ? (<button onClick={() => {
                                this.setState({
                                    editModal: true,
                                    update_fields: {action: "ACTIVATE_OFFICIALKYC_PROFILE", errors: {}, ...d}
                                });
                            }} className={"btn btn-sm btn-round btn-success px-3"}>Activate Profile
                            </button>) : (<button onClick={() => {
                                this.setState({
                                    editModal: true,
                                    update_fields: {action: "EDIT_OFFICIALKYC_PROFILE", errors: {}, ...d}
                                });
                            }} className={"btn btn-sm btn-round btn-danger px-3"}>Edit Profile
                            </button>)}
                        </Access>
                    )
                    let dNew = window.fxn.clean_filtered_columns(d, this.props.data)
                    return dNew
                });

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
    editRequest = (event) => {
        event.preventDefault();
        let id = this.state.id;
        let data = {
            id: this.state.id,
            action: this.state.action,
            name: this.state.name,
            email: this.state.email,
            password: this.state.password,
            group_id: this.state.group_id
        };
        if (!window.confirm("Are you sure that you want to Eit this user?")) return false;

        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        data.reason_purpose = this.state.reason_purpose

        window.app
            .service('core/customer')
            .patch(id, data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'customer updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });

    };
    // handleChange = (e) => {
    //     const {id, value} = e.target
    //     console.debug(e.target.id,id, value);
    //     this.setState(prevState => ({
    //         ...prevState,
    //         [id]: value
    //     }))
    // }
    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState, update_fields: {
                ...prevState.update_fields,
                [id]: value
            }
        }))
    }

}

export default Officials;
import React, {Component} from 'react';
import Table from '../../../components/Table';
import Nav from '../../../components/Nav';
import Modal from "../../../components/Modal";
import {CheckCircle, XCircle} from "react-feather";
import {Link} from "react-router-dom";
import Filter from "../../../components/filter";
import Access from "../../../components/accessManager";


class loan_product extends Component {
    state = {
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        editModal: false,
    };
    timeout = null;

    render() {
        return (
            <div className=''>
                <Nav
                    name='loan_product'
                    buttons={[
                        // {
                        //     text: 'ADD',
                        //     link: '/management/loan_product',
                        // },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 '>
                    <div className='p-4'>
                        <Filter
                            filter={[
                                {
                                    label: 'Status',
                                    name: 'status_id',
                                    options: [
                                        {
                                            label: 'All',
                                            value: 0,
                                            default: true,
                                        },

                                        ...(window.global_status || []).map((d) => {
                                            return {
                                                label: d.status_desc,
                                                value: d.id,
                                            };
                                        }),
                                    ],
                                },
                                // {
                                //     label: 'Currency',
                                //     name: 'currency_id',
                                //     options: [
                                //         {
                                //             label: 'All',
                                //             value: 0,
                                //             default: true,
                                //         },
                                //
                                //         ...window.currency
                                //             .map((d) => {
                                //                 return {
                                //                     label: d.currency_name,
                                //                     value: d.id,
                                //                 };
                                //             }),
                                //     ],
                                // }
                            ]}
                            dateRange={['startdate', 'enddate']}
                            dateColumn={'createdAt'}
                            dateRangeLabel='Date Created'
                            onChange={(filter) => {
                                let {query} = this.state;
                                this.setState({query: {...query, ...filter}});
                                setTimeout(() => {
                                    this.fetchloan_product();
                                }, 0);
                            }}
                        />
                        <Table
                            search={[]}
                            // sort="created_at"
                            // sortDirection={-1}
                            refreshAllowed={true}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchloan_product();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                        <Modal
                            visible={this.state.editModal}
                            close={() => this.setState({editModal: false})}>
                            <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                            <form onSubmit={this.editRequest}>

                                <input className="form-control bg-light text-input" name="action"
                                       id="action"
                                       hidden={true}
                                       disabled={true}
                                       type="text" value={'UPDATE_LOAN_PRODUCT'} onChange={this.handleChange}
                                       style={{width: '400px'}}/>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">interest_rate</span>
                                    <input className="form-control bg-light text-input" name="interest_rate"
                                           id="interest_rate"
                                           type="text" value={this.state.interest_rate} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>

                                <div className="mx-3 d-inline-block mb-3 ">
                                    <span className="ml-1 mb-2 d-block text-capitalize" style={{color: "red"}}>*Reason/Purpose of Change</span>
                                    <input className="form-control bg-light text-input border-danger"
                                           name="reason_purpose"
                                           id="reason_purpose"
                                           type="text"
                                           aria-multiline={3}
                                           required={true}
                                           onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>

                                <div
                                    className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button
                                        className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit
                                    </button>
                                </div>
                            </form>
                        </Modal>
                    </div>
                </div>
            </div>
        );
    }

    fetchloan_product = () => {
        this.setState({table_loading: true});

        window.app
            .service('core/loan_product')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    d['Update Interest'] = (
                        <Access permission='ACTIONS_LOAN_PRODUCT_EDIT'>

                            &nbsp;&nbsp;
                            <button onClick={() => {
                                this.setState({
                                    action: "UPDATE_LOAN_PRODUCT_INTEREST",
                                    editModal: true,
                                    ...d
                                });
                            }} className={"btn btn-sm btn-round btn-outline-danger px-3"}>Update Interest
                            </button>
                        </Access>
                    )
                    let dNew = window.fxn.clean_filtered_columns(d, this.props.data)
                    return dNew
                });
                console.debug("loan_product", response)

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };
    editRequest = (event) => {
        event.preventDefault();
        let id = this.state.id;
        let data = {
            id: this.state.id,
            action: this.state.action,
            interest_rate: this.state.interest_rate,
        };
        if (!window.confirm("Are you sure that you want to Eit this user?")) return false;

        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        data.reason_purpose = this.state.reason_purpose
        window.app
            .service('core/loan_product')
            .patch(id, data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'loan_product updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                console.error(err);
            });

    };
    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
}

export default loan_product;
import React, {Component} from 'react';
import Table from '../../components/Table';
import Nav from '../../components/Nav';
import moment from 'moment';
import Modal from "../../components/Modal";
import {CheckCircle, UserCheck, XCircle} from "react-feather";
import Filter from "../../components/filter";
import Form from "../../components/form";

let SECONDFACTOR_OPTIONS = [{
    name: 'Disabled',
    value: 0,
},

    {
        name: 'EMAIL',
        value: 1,
    },
    {
        name: 'SMS',
        value: 2,
    },
    {
        name: 'Both',
        value: 3,
    }
];

class Admins extends Component {
    state = {
        new_password: "KEEP_CURRENT",
        tableData: {data: []},
        response: {data: []},
        tableError: false,
        query: {},
        filter: {},
        table_loading: false,
        editModal: false,
        addModal: false,
    };
    timeout = null;

    render() {

        return (
            <div className=''>
                <Nav
                    name='System User'
                    buttons={[
                        {
                            text: 'Add System User',
                            onClick: () => {
                                this.setState({addModal: true});
                            },
                            access: 'ADMIN_CREATE',
                        },
                    ]}></Nav>
                <div className='mt-3 table-card border-0 card shado mx-3 '>
                    <div className='p-4'>

                        <Table
                            search={['name']}
                            // sort="created_at"
                            // sortDirection={-1}
                            refreshAllowed={true}
                            data={this.state.tableData}
                            fetch={(params) => {
                                this.setState({query: params});
                                setTimeout(() => {
                                    this.fetchAdmins();
                                }, 0);
                            }}
                            loading={this.state.table_loading}
                            fetchError={this.state.tableError}
                        />
                        <Modal
                            visible={this.state.editModal}
                            close={() => this.setState({editModal: false})}>
                            <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                            <form onSubmit={this.editRequest}>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Name</span>
                                    <input className="form-control bg-light text-input" name="name" id="name"
                                           type="text" value={this.state.name} onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Email</span>
                                    <input className="form-control bg-light text-input" name="email" id="email"
                                           type="text" value={this.state.email} onChange={this.handleChange}
                                           style={{width: '400px'}}/>

                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">MSISDN</span>
                                    <input className="form-control bg-light text-input" name="msisdn" id="msisdn"
                                           type="text" value={this.state.msisdn} onChange={this.handleChange}
                                           style={{width: '400px'}}/>

                                </div>
                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">New Password</span>
                                    <input className="form-control bg-light text-input" type="text" name="new_password"
                                           id="new_password" value={this.state.new_password}
                                           onChange={this.handleChange}
                                           style={{width: '400px'}}/>
                                </div>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">2FA</span>
                                    <select style={{width: '400px'}} className="form-control bg-light text-input"
                                            name="secondFactor" id="secondFactor" type="number"
                                            value={this.state.secondFactor}
                                            onChange={this.handleChange}>
                                        {SECONDFACTOR_OPTIONS.map((d) => {
                                            return <option
                                                selected={d.value === this.state.secondFactor ? "selected" : ""}
                                                value={d.value}>{d.name}</option>;
                                        })}
                                    </select>
                                </div>

                                <div className="mx-3 d-inline-block mb-3">
                                    <span className="ml-1 mb-2 d-block text-capitalize">Group</span>
                                    <select style={{width: '400px'}} className="form-control bg-light text-input"
                                            name="group_id" id="group_id" type="number" value={this.state.group_id}
                                            onChange={this.handleChange}>
                                        {window.groups.map((d) => {
                                            return <option selected={d.id === this.state.group_id ? "selected" : ""}
                                                           value={d.id}>{d.name}</option>;
                                        })}
                                    </select>
                                </div>

                                <div
                                    className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                                    <button
                                        className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Edit
                                    </button>
                                </div>
                            </form>
                        </Modal>


                        <Modal
                            visible={this.state.addModal}
                            close={() => this.setState({addModal: false})}>
                            <div className='d-flex flex-row align-items-center'>
                                <UserCheck className='mr-3'></UserCheck>
                                <h5 className='m-0 mt-1'>Add A System Admin</h5>
                            </div>
                            <div className='mt-3'>
                                <Form
                                    inputs={[
                                        {
                                            label: 'name',
                                            name: 'name',
                                            type: 'text',
                                        },
                                        {
                                            label: 'Phone Number',
                                            name: 'msisdn',
                                            type: 'tel',
                                        },
                                        {
                                            label: 'email',
                                            name: 'email',
                                            type: 'email',
                                        },
                                        {
                                            label: '2FA Auth',
                                            name: 'secondFactor',
                                            type: 'select',
                                            options: [
                                                {
                                                    name: 'Disabled',
                                                    value: 0,
                                                },

                                                {
                                                    name: 'EMAIL',
                                                    value: 1,
                                                },
                                                {
                                                    name: 'SMS',
                                                    value: 2,
                                                },
                                                {
                                                    name: 'Both',
                                                    value: 3,
                                                },
                                            ],
                                        },

                                        {
                                            label: 'status',
                                            name: 'status',
                                            type: 'select',
                                            options: [
                                                {
                                                    name: 'Active',
                                                    value: 1,
                                                },
                                                {
                                                    name: 'Inactive',
                                                    value: 0,
                                                },
                                            ],
                                        },

                                        {
                                            label: 'group',
                                            name: 'group_id',
                                            type: 'select',
                                            options: window.groups.map((d) => {
                                                return {
                                                    name: d.name,
                                                    value: d.id,
                                                };
                                            }),
                                        },
                                    ]}
                                    submit={(data) => {
                                        this.createAdmin(data);
                                    }}
                                />
                            </div>
                        </Modal>


                    </div>
                </div>
            </div>
        );
    }

    fetchAdmins = () => {
        this.setState({table_loading: true});

        window.app
            .service('prerequisites/admins')
            .find({
                query: {
                    ...this.state.query,
                },
            })
            .then((response) => {
                response.data = response.data.map((d) => {
                    // let dNew = window.fxn.clean_filtered_columns(d, this.props.data)

                    if (d.secondFactor_status === 1) {
                        d.secondFactor = 0
                    } else {
                        d.secondFactor = {"DISABLED": 0, "EMAIL": 1, "SMS": 2, "EMAIL_SMS": 3}[d.secondFactor_selected]
                    }


                    return {
                        name: d.name,
                        email: d.email,
                        group: d.group?.name,
                        "2FA": <>
                            <p>{d.secondFactor_status === 1 ? "Disabled" : d.secondFactor_selected}<br></br>
                                {(!JSON.parse(d.addOnData)?.msisdn) ?
                                    <code>!Update MSISDN!</code> : <></>}</p>
                        </>,
                        // secondFactor_selected: d.secondFactor_selected,
                        // secondFactor_status: d.secondFactor_status,
                        createdAt: d.createdAt && moment(d.createdAt).format('YYYY-MM-DD hh:mm:ss'),
                        action: (
                            <div className="d-flex flex-row">
                                <button
                                    onClick={() => {
                                        this.setState({
                                            editModal: true,
                                            activeDetails: d,
                                            name: d.name,
                                            email: d.email,
                                            msisdn: JSON.parse(d.addOnData)?.msisdn,
                                            id: d.id,
                                            group: d.group?.name,
                                            secondFactor: d.secondFactor,
                                            secondFactor_selected: d.secondFactor_selected,
                                            secondFactor_status: d.secondFactor_status
                                        });

                                    }}
                                    className="option-card no-wrap pr-3 d-md-flex d-inline-block my-2 flex-row btn align-items-center btn-primary btn-round mr-3"
                                >
                                    Edit
                                </button>

                            </div>
                        )
                    };
                });
                console.debug("ADMINS", response)

                this.setState({tableData: response, table_loading: false});
            })
            .catch((err) => {
                this.setState({table_loading: false});
                console.error(err);
            });
    };


    createAdmin = (data) => {
        window.alert2.show({
            loader: true,
            title: 'Creating System User ...',
            buttons: false,
        });


        if (data.secondFactor === 0) {
            data.secondFactor_status = 1
            data.secondFactor_selected = ""
        } else {
            data.secondFactor_status = 0
            data.secondFactor_selected = {1: "EMAIL", 2: "SMS", 3: "EMAIL_SMS"}[data.secondFactor]
        }
        delete data.secondFactor;
        data.password = Math.random().toString(36).slice(2, 15).toUpperCase()
        console.debug("Password Created:", data.password)

        window.app
            .service('prerequisites/admins')
            .create(data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'System User created Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },

                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });
    };

    editRequest = (event) => {
        event.preventDefault();
        let id = this.state.id;
        let data = {
            id: this.state.id,
            name: this.state.name,
            email: this.state.email,
            secondFactor: this.state.secondFactor,
            addOnData: JSON.stringify({msisdn: this.state.msisdn, email: this.state.email}),
            new_password: this.state.new_password,
            group_id: this.state.group_id
        };
        if (
            !window.confirm("Are you sure that you want to Eit this user?")
        )
            return false;


        console.debug("Update Data", data, this.state)
        if (parseInt(data.secondFactor) === 0) {
            data.secondFactor_status = 1
            data.secondFactor_selected = ""
        } else {
            data.secondFactor_status = 0
            data.secondFactor_selected = {1: "EMAIL", 2: "SMS", 3: "EMAIL_SMS"}[parseInt(data.secondFactor)]
        }

        if (data.new_password !== "KEEP_CURRENT") {
            data.password = data.new_password // Math.random().toString(36).slice(2, 15).toUpperCase();
        }


        delete data.secondFactor;
        delete data.new_password;

        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('prerequisites/admins')
            .patch(id, data)
            .then((response) => {
                this.setState(response);

                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Admin updated Successfully',
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
                console.error(err);
            });

    };
    handleChange = (e) => {
        const {id, value} = e.target
        console.debug("handleChange", e.target.id, id, value);
        this.setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }
}

export default Admins;
import React, {Component} from 'react';
import Access from "../../../components/accessManager";
import Modal from "../../../components/Modal";
import {CheckCircle, Info, XCircle} from 'react-feather';
import ReactJson from "react-json-view";
import {useRef} from 'react';

class Tab_Summary extends Component {
    state = {
        editModal: false,
        editSendRequest: false,
        update_fields: {errors: {}},
        data: {}, wallets: [],
        images: []
    };

    render() {
        return (
            <div>
                <div className='row p-3'>

                    <div className='col-md-6'>
                        <div className='card'>
                            <table className='table table-striped table-hover'>
                                <thead>
                                <th>Field</th>
                                <th>Data</th>
                                </thead>

                                {Object.keys(this.state.data).map((d, i) => {
                                    if (d.includes("Obj")) {
                                        return (
                                            <tr key={i}>
                                                <td className='text-capitalize'>
                                                    {d.replace(/\_/g, ' ')}
                                                </td>
                                                <td>
                                                    {this.state?.data[d] &&
                                                        <ReactJson
                                                            displayDataTypes={false}
                                                            displayObjectSize={false}
                                                            src={this.state?.data[d]}
                                                        />}
                                                </td>
                                            </tr>
                                        );

                                    }
                                    return (
                                        <tr key={i}>
                                            <td className='text-capitalize'>
                                                {d.replace(/\_/g, ' ')}
                                            </td>
                                            <td>{this.state.data[d] ? this.state.data[d] : '-'}</td>
                                        </tr>
                                    );
                                })}

                            </table>
                        </div>
                    </div>

                    <div className='col-md-6'>

                        <div className='card'>
                            <table className='table table-striped table-hover'>

                                {/*    "profilePicObj": {*/}
                                {/*    "id": 1,*/}
                                {/*    "name": "photo.jpg",*/}
                                {/*    "description": "Photo face"*/}
                                {/*},*/}
                                {/*    "signatureKeyObj": {*/}
                                {/*    "id": 2,*/}
                                {/*    "name": "photo.jpg",*/}
                                {/*    "description": "Photo face"*/}
                                {/*},*/}


                                <tr key={"0"}>
                                    <td className='text-capitalize'>
                                        <b>Profile Picture</b>
                                        <br/>
                                        <small>{this.state?.data?.profilePicObj?.description}</small>
                                    </td>
                                    <td>
                                        <p>{this.state?.data?.profilePicObj?.location}</p>
                                        <img
                                            src={"/" + this.state?.data?.profilePicObj?.location}
                                            className='main-logo' alt=''/>
                                    </td>
                                </tr>


                                <tr key={"0"}>
                                    <td className='text-capitalize'>
                                        <b>Signature</b>
                                        <br/>
                                        <small>{this.state?.data?.profilePicObj?.description}</small>
                                    </td>
                                    <td>
                                        <p>{this.state?.data?.profilePicObj?.location}</p>
                                        <img
                                            src={"/" + this.state?.data?.signatureKeyObj?.location}
                                            className='main-logo' alt=''/>
                                    </td>
                                </tr>


                            </table>
                        </div>


                    </div>
                </div>


            </div>

        );
    }

    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState, update_fields: {
                ...prevState.update_fields,
                [id]: value
            }
        }))
    }

    validate(msisdn) {
        let errors = {};
        let isValid = true;
        if (!msisdn) {

            isValid = false;

            errors["msisdn"] = "Please enter your phone number.";

        }
        if (typeof msisdn !== "undefined") {

            var pattern = new RegExp(/^(\+){0,1}(254|0){0,1}(\d{9})$/);

            if (!pattern.test(msisdn)) {

                isValid = false;

                errors["msisdn"] = "Please enter valid phone number.";

            }

        }
        this.setState({

            errors: errors

        });


        return isValid;

    }

    editSendRequest
        = (event) => {
        event.preventDefault();


        let id = this.state.data.id;
        let data = {
            action: this.state.update_fields.action,
            ...this.state.update_fields.msisdn ? {msisdn: this.state.update_fields.msisdn} : {},
            ...this.state.update_fields.serial_no ? {serial_no: this.state.update_fields.serial_no} : {},
            ...this.state.update_fields.id_number ? {id_number: this.state.update_fields.id_number} : {},
            ...this.state.update_fields.first_name ? {first_name: this.state.update_fields.first_name} : {},
            ...this.state.update_fields.other_name ? {other_name: this.state.update_fields.other_name} : {},
            ...this.state.update_fields.surname ? {surname: this.state.update_fields.surname} : {},
            ...this.state.update_fields.account_no ? {account_no: this.state.update_fields.account_no} : {},
            ...this.state.update_fields.status ? {status: this.state.update_fields.status} : {},
            ...this.state.update_fields.amount_eligible ? {amount_eligible: this.state.update_fields.amount_eligible} : {}
        };
        if (!window.confirm("Are you sure that you want to update this record?")) return false;

        data.reason_purpose = this.state.update_fields.reason_purpose

        window.app
            .service('core/data')
            .patch(id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'User Profile',
                    message: "Update Request Successful",
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });

            })
            .catch((err) => {
                console.debug(err)
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            });

    };

    componentDidMount() {
        let data = this.props.data;
        this.setState({data});
    }

    get_action_results = ({action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(this.state.data.id, {query: {action_id, request_data_id: this.state.data.id}})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        // window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

    // update_data
    execute_action = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .create({
                action_id,
                action_name,
                action: `${action_id}`.toUpperCase(),
                request_data_id: this.state.data.id
            })
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: action_name,
                    message: JSON.stringify(response),
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: action_name + ' Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };


    update_data = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(37, {})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

}

export default Tab_Summary;

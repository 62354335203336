import React, {Component} from 'react';
import Access from "../../../components/accessManager";
import Modal from "../../../components/Modal";
import {CheckCircle, Info, XCircle} from 'react-feather';
import ReactJson from "react-json-view";

class Tab_Summary extends Component {
    state = {
        editModal: false,
        editSendRequest: false,
        update_fields: {errors: {}},
        data: {}, wallets: []
    };

    render() {
        return (
            <div>
                <div className='row p-3'>
                    <div className='col-md-6'>
                        <div className='card'>
                            <table className='table table-striped table-hover'>
                                <thead>
                                <th>Field</th>
                                <th>Data</th>
                                </thead>

                                {Object.keys(this.state.data).map((d, i) => {
                                    if (d.includes("Obj")) {
                                        return (
                                            <tr key={i}>
                                                <td className='text-capitalize'>
                                                    {d.replace(/\_/g, ' ')}
                                                </td>
                                                <td>
                                                    {this.state?.data[d] &&
                                                        <ReactJson
                                                            displayDataTypes={false}
                                                            displayObjectSize={false}
                                                            src={this.state?.data[d]}
                                                        />}
                                                </td>
                                            </tr>
                                        );

                                    }
                                    return (
                                        <tr key={i}>
                                            <td className='text-capitalize'>
                                                {d.replace(/\_/g, ' ')}
                                            </td>
                                            <td>{this.state.data[d] ? this.state.data[d] : '-'}</td>
                                        </tr>
                                    );
                                })}

                            </table>
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <Access permission='eligibility_check_ACTION_VIEW'>

                            <div className='card'>
                                <table className='table table-striped table-hover'>
                                    <thead>
                                    <td>Action Shield</td>
                                    <td>****</td>
                                    </thead>
                                    <Access permission='ACTIONS_eligibility_check_EDIT_PROFILE_VIEW'>
                                        <tr key={"1"}>
                                            <td className='text-capitalize'>
                                                <b>Profile</b>
                                            </td>


                                            <td>

                                                <Access permission='ACTIONS_eligibility_check_EDIT_PROFILE'>

                                                    &nbsp;&nbsp;
                                                    <button onClick={() => {

                                                        let cc = this.state.data

                                                        // if (!cc.account_no) cc['account_no'] = "-"
                                                        // if (!cc.serial_no) cc['serial_no'] = "-"
                                                        this.setState({
                                                            editModal: true,
                                                            update_fields: {action: "EDIT_PROFILE", errors: {}, ...cc}
                                                        });

                                                    }} className={"btn btn-sm btn-round btn-outline-primary px-3"}>Edit
                                                        Profile
                                                    </button>
                                                </Access>

                                                <Access permission='ACTIONS_eligibility_check_UPDATE_LIMIT'>

                                                    &nbsp;&nbsp;
                                                    <button onClick={() => {
                                                        this.setState({
                                                            editModal: true,
                                                            update_fields: {
                                                                action: "UPDATE_LIMIT",
                                                                errors: {},
                                                                amount_eligible: this.state.data.amount_eligible
                                                            }
                                                        });
                                                    }} className={"btn btn-sm btn-round btn-outline-danger px-3"}>Update
                                                        Loan Limit Score
                                                    </button>

                                                </Access>

                                                <Access permission='ACTIONS_eligibility_check_RESET_PIN'>
                                                    &nbsp;&nbsp;
                                                    <button onClick={() => {
                                                        this.execute_action({
                                                            action_name: "Reset Pin",
                                                            action_id: "reset_pin"
                                                        })
                                                        // this.setState({editModal: true, update_fields:{action:"PIN_RESET",errors: {},id:this.state.pipelines.id}});
                                                    }} className={"btn btn-sm btn-round btn-info px-3"}>Resend User
                                                        Reset Pin
                                                    </button>
                                                </Access>

                                                <Access permission='ACTIONS_eligibility_check_BLOCK_USER'>

                                                    &nbsp;&nbsp;
                                                    {this.state.data.status !== 1 ? (<button onClick={() => {
                                                        this.setState({
                                                            editModal: true,
                                                            update_fields: {
                                                                action: "ACTIVATE_USER",
                                                                errors: {},
                                                                account_no: this.state.data.account_no,
                                                                status: "1"
                                                            }
                                                        });
                                                    }} className={"btn btn-sm btn-round btn-success px-3"}>Activate User
                                                    </button>) : (<button onClick={() => {
                                                        this.setState({
                                                            editModal: true,
                                                            update_fields: {
                                                                action: "BLOCK_USER",
                                                                errors: {},
                                                                status: "5"
                                                            }
                                                        });
                                                    }} className={"btn btn-sm btn-round btn-danger px-3"}>Block User
                                                    </button>)}
                                                </Access>

                                                &nbsp;
                                            </td>
                                        </tr>
                                    </Access>


                                </table>
                            </div>
                        </Access>
                        <br/>
                        <br/>


                    </div>
                </div>


                <Modal
                    visible={this.state.editModal}
                    close={() => this.setState({editModal: false, update_fields: {errors: {}}})}>
                    <h5 className="font-weight-bold" style={{margin: "10px"}}>{this.state.title}</h5>

                    <form onSubmit={this.editSendRequest}>
                        {this.state.update_fields?.id && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Id</span>
                            <input className="form-control bg-light text-input" name="id" id="id" type="text"
                                   value={this.state.update_fields?.id}
                                   onChange={this.handleChange} style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.surname && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Surname</span>
                            <input className="form-control bg-light text-input" name="surname" id="surname" type="text"
                                   value={this.state.update_fields?.surname} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.first_name && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">FirstName</span>
                            <input className="form-control bg-light text-input" name="first_name" id="first_name"
                                   type="text"
                                   value={this.state.update_fields?.first_name} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.other_name && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">OtherName</span>
                            <input className="form-control bg-light text-input" name="other_name" id="other_name"
                                   type="text"
                                   value={this.state.update_fields?.other_name} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.amount_eligible >= 0 && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Loan Limit</span>
                            <input name="amount_eligible" id="amount_eligible" type="text"
                                   value={this.state.update_fields?.amount_eligible} onChange={this.handleChange}
                                   style={{width: '400px'}}/>

                        </div>)}
                        {this.state.update_fields?.serial_no && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">ID Serial No</span>
                            <input type="text" name="serial_no" id="serial_no"
                                   value={this.state.update_fields?.serial_no} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.msisdn && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Msisdn</span>
                            <input type="text" name="msisdn" id="msisdn" value={this.state.update_fields?.msisdn}
                                   onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.id_number && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">ID Number</span>
                            <input type="text" name="id_number" id="id_number"
                                   value={this.state.update_fields?.id_number} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                        </div>)}
                        {this.state.update_fields?.account_no && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">Account No:</span>
                            <input className="form-control bg-light text-input" name="account_no" id="account_no"
                                   type="text"
                                   value={this.state.update_fields?.account_no} onChange={this.handleChange}
                                   style={{width: '400px'}}/>
                            <div className="text-danger">{this.state.update_fields?.errors.account_no}</div>
                        </div>)}
                        {/* {window.user.admin['group']['name'] === 'System Admins' && */}
                        {this.state.update_fields?.status && (<div className="mx-3 d-inline-block mb-3">
                            <span className="ml-1 mb-2 d-block text-capitalize">status:</span>
                            <select className="form-control bg-light text-input" name="status" id="status" type="select"
                                    value={this.state.update_fields?.status} onChange={this.handleChange}
                                    style={{width: '400px'}}>
                                <option value="1">ACTIVE</option>
                                <option value="5">SUSPEND</option>
                                <option value="6">DEACTIVATE</option>
                            </select>
                        </div>)}
                        {/* } */}
                        <div className="my-3 d-flex flex-row justify-content-between mt-5 justify-content-between">
                            <button className="btn btn-primary px-3 px-md-5 ml-3 font-weight-bold btn-round">Update
                            </button>
                        </div>
                    </form>
                </Modal>


            </div>

        );
    }

    handleChange = (e) => {
        const {id, value} = e.target
        console.debug(e.target.id);
        this.setState(prevState => ({
            ...prevState, update_fields: {
                ...prevState.update_fields,
                [id]: value
            }
        }))
    }

    validate(msisdn) {
        let errors = {};
        let isValid = true;
        if (!msisdn) {

            isValid = false;

            errors["msisdn"] = "Please enter your phone number.";

        }
        if (typeof msisdn !== "undefined") {

            var pattern = new RegExp(/^(\+){0,1}(254|0){0,1}(\d{9})$/);

            if (!pattern.test(msisdn)) {

                isValid = false;

                errors["msisdn"] = "Please enter valid phone number.";

            }

        }
        this.setState({

            errors: errors

        });


        return isValid;

    }

    editSendRequest
        = (event) => {
        event.preventDefault();


        let id = this.state.data.id;
        let data = {
            action: this.state.update_fields.action,
            ...this.state.update_fields.msisdn ? {msisdn: this.state.update_fields.msisdn} : {},
            ...this.state.update_fields.serial_no ? {serial_no: this.state.update_fields.serial_no} : {},
            ...this.state.update_fields.id_number ? {id_number: this.state.update_fields.id_number} : {},
            ...this.state.update_fields.first_name ? {first_name: this.state.update_fields.first_name} : {},
            ...this.state.update_fields.other_name ? {other_name: this.state.update_fields.other_name} : {},
            ...this.state.update_fields.surname ? {surname: this.state.update_fields.surname} : {},
            ...this.state.update_fields.account_no ? {account_no: this.state.update_fields.account_no} : {},
            ...this.state.update_fields.status ? {status: this.state.update_fields.status} : {},
            ...this.state.update_fields.amount_eligible ? {amount_eligible: this.state.update_fields.amount_eligible} : {}
        };
        if (!window.confirm("Are you sure that you want to update this record?")) return false;

        window.app
            .service('core/data')
            .patch(id, data)
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'User Profile',
                    message: "Update Request Successful",
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });

            })
            .catch((err) => {
                console.debug(err)
                if (err.code !== 500) {
                    window.alert2.show({
                        loader: false,
                        icon: <Info size={60}/>,
                        title: 'MakerChecker Alerts',
                        message: err.message,
                        buttons: true,
                        onSubmit: () => {
                            window.location.reload();
                        },
                    });

                    return;
                }

                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'/>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
            });

    };

    componentDidMount() {
        let data = this.props.data;
        this.setState({data});
    }

    get_action_results = ({action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(this.state.data.id, {query: {action_id, request_data_id: this.state.data.id}})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        // window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

    // update_data
    execute_action = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .create({
                action_id,
                action_name,
                action: `${action_id}`.toUpperCase(),
                request_data_id: this.state.data.id
            })
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: action_name,
                    message: JSON.stringify(response),
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: action_name + ' Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };


    update_data = ({action_name, action_id}) => {
        window.alert2.show({
            loader: true,
            title: 'Request Action Executing ...',
            buttons: false,
        });

        window.app
            .service('core/actions')
            .get(37, {})
            .then((response) => {
                window.alert2.show({
                    loader: false,
                    icon: <CheckCircle size={60}></CheckCircle>,
                    title: 'Action Results Received',
                    message: response.message,
                    buttons: true,
                    onSubmit: () => {
                        window.location.reload();
                    },
                });
                this.setState(response);
            })
            .catch((err) => {
                window.alert2.show({
                    loader: false,
                    icon: <XCircle size={60} className='text-danger'></XCircle>,
                    title: 'Error',
                    message: err.message,
                    buttons: true,
                });
            });
    };

}

export default Tab_Summary;

import React, {Component} from 'react';
import moment from 'moment-timezone';
import {DateRangePicker,} from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';

class Filter extends Component {

    state = {
        ranges: {
            startDate: this.props.range?.startDate,
            endDate: this.props.range?.endDate,
        },
        query: {},
    };

    render() {
        return (
            <>
                <div className='d-flex flex-row justify-content-between mb-3 position-relative'>
                    <div className='d-flex flex-row'>
                        {this.props.filter &&
                            this.props.filter.map((d, i) => (
                                <div className='mx-2 flex-column' key={i}>
                                    <small className='mb-2 font-weight-bold'>{d.label}</small>
                                    <select
                                        name=''
                                        id=''
                                        className='form-control py-1 filter-option'
                                        onChange={(e) => {
                                            let {query} = this.state;

                                            let option = d.options.filter(c => {
                                                if (c == undefined) {
                                                    return false
                                                }
                                                return true
                                            })[e.target.value];

                                            if (option.default) {
                                                console.debug(option.default);
                                                delete query[d.name];
                                            } else {
                                                query[d.name] = option.value;
                                            }

                                            this.setState({query});
                                            setTimeout(() => {
                                                this.updateFilter();
                                            }, 0);
                                        }}
                                    >
                                        {d.options.filter(c => {
                                            if (c == undefined) {
                                                return false
                                            }
                                            return true
                                        }).map((d1, i1) => (
                                            <option value={i1} key={i1}>
                                                {d1.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            ))}
                    </div>

                    {this.props.dateRange && (
                        <div>
                            <div htmlFor=''>
                                <small className='font-weight-bold mb-2'>
                                    {this.props.dateRangeLabel || 'Date Range'}
                                </small>
                            </div>
                            <DateRangePicker
                                startDate={this.state.ranges.startDate} // momentPropTypes.momentObj or null,
                                startDateId='start_date' // PropTypes.string.isRequired,
                                endDate={this.state.ranges.endDate} // momentPropTypes.momentObj or null,
                                endDateId='end_date' // PropTypes.string.isRequired,
                                onDatesChange={({startDate, endDate}) => {
                                    this.updateDateChange(startDate, endDate);
                                }}
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={(focusedInput) =>
                                    this.setState({focusedInput})
                                } // PropTypes.func.isRequired,
                                isOutsideRange={() => false}
                            />
                            <select
                                className='form-control'
                                onChange={(event) => {
                                    let x = [
                                        {a: null, b: null},
                                        {a: moment().startOf('day'), b: moment().endOf('day')},
                                        {
                                            a: moment().subtract(1, 'day').startOf('day'),
                                            b: moment().subtract(1, 'day').endOf('day')
                                        },
                                        {a: moment().subtract(2, 'day').startOf('day'), b: moment().endOf('day')},
                                        {a: moment().startOf('week'), b: moment().endOf('day')},
                                        {a: moment().startOf('month'), b: moment().endOf('day')},
                                        {a: moment().startOf('year'), b: moment().endOf('day')}
                                    ]
                                    let dd = event.target.value
                                    let {a, b} = x[dd]
                                    if (a == null) {
                                        window.location.reload()
                                    }
                                    this.updateDateChange(a, b)
                                }}>
                                <option>Select date options</option>
                                <option value={1}>Today</option>
                                <option value={2}>Yesterday</option>
                                <option value={3}>Past 2Days</option>
                                <option value={4}>This week</option>
                                <option value={5}>This Month</option>
                                <option value={6}>This Year</option>
                                <option value={0}>Clear</option>

                            </select>

                        </div>
                    )}
                </div>
            </>
        );
    }

    componentDidMount() {
        window.moments = moment
    }

    updateFilter = () => {
        let q = this.state.query

        if (q.startdate && q.enddate) {
            q[this.props.dateColumn] = {
                $gte: q.startdate,
                $lte: q.enddate,
            };
            delete q.startdate;
            delete q.enddate;
        } else {
            if (q.startdate) {
                q[this.props.dateColumn] = {
                    $gte: q.startdate,
                };
                delete q.startdate;
            } else if (q.enddate) {
                q[this.props.dateColumn] = {
                    $lte: q.enddate,
                };
                delete q.enddate;
            }

        }

        if (this.props.onChange) this.props.onChange(q);
    };

    updateDateChange(startDate, endDate) {
        let {query, ranges} = this.state;
        console.debug("startDate")
        console.debug(startDate)
        console.debug("endDate")
        console.debug(endDate)


        if (startDate) {
            query[this.props.dateRange[0]] = startDate.format(
                'YYYY-MM-DD HH:mm:ss'
            );
            ranges.startDate = startDate;
        } else {
            ranges.startDate = null;
            delete query[this.props.dateRange[0]]
        }

        if (endDate) {
            query[this.props.dateRange[1]] = endDate.format(
                'YYYY-MM-DD HH:mm:ss'
            );
            ranges.endDate = endDate;
        } else {
            ranges.endDate = null;
            delete query[this.props.dateRange[1]]
        }

        this.setState({ranges, query});

        setTimeout(() => {
            this.updateFilter();
        }, 0);


    }
}

Filter.defaultProps = {
    dateRange: ['startdate', 'enddate'],
    dateRangeLabel: 'Transaction Time',
    dateColumn: "date_created"
}

export default Filter;

import React, {Component} from 'react';
import {ChevronDown, User, X} from 'react-feather';
import {Link} from 'react-router-dom';
import Access from './accessManager';
import logo from "../img/logo.png";
import Clock from 'react-live-clock';

class Nav extends Component {
    state = {};

    render() {
        return (
            <div
                className='py-3 px-4 d-flex flex-row justify-content-between align-items-center d-flex flex-row nav mt-3 table-card border-0 card shado mx-3 '>

                <h2 className='font-weight-bold m-0 text-capitalize'>
                    {this.props?.name?.replace(/_/g, ' ')}
                </h2>
                <div className='position-relative d-flex flex-row align-items-center '>
                    {this.props.buttons &&
                        this.props.buttons.map((d, i) => (
                            <Access permission={d.permission || 'all'}>
                                <Link
                                    to={d.link}
                                    key={i}
                                    onClick={d.onClick}
                                    className='mx-2 btn btn-primary rounded px-4 font-weight-bold'>
                                    {d.text}
                                </Link>
                            </Access>
                        ))}
                    <div>
                        <div
                            className={
                                'action-icon align-items-center d-md-flex flex-row d-none ' +
                                (this.state.showProfile ? 'active' : '')
                            }
                            onClick={() =>
                                this.setState({showProfile: !this.state.showProfile})
                            }>
                            <div className='user-avatar d-flex flex-row align-items-center justify-content-center'>
                                <User></User>
                            </div>
                            <div className='font-weight-bold'>
                                &nbsp;&nbsp;{window.user.admin?.name}&nbsp;&nbsp;
                                , &nbsp;&nbsp;{window.user.admin?.group?.name}&nbsp;&nbsp;
                            </div>

                            <ChevronDown size={17} className='ml-2'/>
                        </div>
                        <b>
                            <Clock format={'YYYY-MM-DD HH:mm:ss'} ticking={true} timezone={'Africa/Kinshasa'}/>
                        </b> Kinshasa, Congo Dem. Rep


                        {window.user && (
                            <div
                                className={
                                    'profile-card card shadow ' +
                                    (this.state.showProfile ? 'active' : '')
                                }>
                                <div className='p-3 position-relative'>
                                    <div className='close-action' onClick={() =>
                                        this.setState({showProfile: !this.state.showProfile})
                                    }>
                                        <X className='m-2' size={17}></X>
                                    </div>
                                    <div className='font-weight-bold'>
                                        {window.user.admin.name}
                                    </div>
                                    <div>
                                        <small className='text-capitalize'>
                                            Last Logged In:{' '}
                                            <code>{window.user.admin.last_login_date}</code>
                                        </small>
                                        <br/>
                                        <small className='text-capitalize'>
                                            Last IP: <code>{window.user.admin.last_ip_login}</code>
                                        </small>
                                    </div>
                                </div>
                                <button
                                    className='btn btn-primary text-center'
                                    onClick={() => window.logout()}>
                                    Logout
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default Nav;
